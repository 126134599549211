<template>
  <div class="container">
      <div class="pagetitle">
          首页 > {{$route.query.type==1?'案例展示':'行业动态'}} > {{info.title}}
          <div class="date">
              <div class="day">{{date.day}}</div>
              <p>{{date.month}}</p>
              <p>{{date.year}}</p>
          </div>
      </div>
      <div class="title">{{info.title}}</div>
      <div class="small-title">
          <p>发布者：{{info.createUser}}</p>
          <p>浏览量：{{showCount}}</p>
      </div>
      <!-- <div class="text" v-html="info.content">
     
      </div> -->
      <div class="ql-container ql-snow " style="width:80%;height:auto !important;margin:0px auto">
            <div class="ql-editor" v-html="info.content" style="border:none;line-height:35px;">
            </div>
        </div>
      <!-- <div class="img-box">
          <img v-lazy="require('../assets/images/dongtai.webp')">
      </div> -->
      <Loading v-if="Loading"></Loading>
      <div class="page">
          <p style="text-align:left" @click="checkArticle(prevArr[0].id)"><i v-if="loadling1" style="color:#4D97FF;margin-right:5px" class="el-icon-loading"></i>上一篇：{{prevArr[0].title}}</p>
          <p style="text-align:right" @click="checkArticle(nextArr[0].id)"><i v-if="loadling2" style="color:#4D97FF;margin-right:5px" class="el-icon-loading"></i>下一篇：{{nextArr[0].title}}</p>
      </div>
  </div>
</template>

<script>
import Loading from '../components/Loading.vue'
export default {
    components:{Loading},
    data(){
        return{
            id:-1,
            date:{
                year:"",
                month:"",
                day:"",
            },
            info:{},
            prevArr:[{
                title:'',
                id:-1,
            }],
            nextArr:[{
                title:'',
                id:-1,
            }],
            Loading:true,
            loadling1:true,
            loadling2:true,
            idList:[],
            showCount:0,
        }
    },
    methods:{
        //获取文章内容
        getArticleContent(){
            let index = this.idList.indexOf(this.id);
            let prevId = this.idList[index-1];
            let nextId = this.idList[index+1];
            this.$api.getArticleContent({id:this.id,type:this.$route.query.type,state:1}).then(res=>{
                if(res.data.code == 200){
                    this.info = res.data.data[0];
                    this.Loading = false;
                    //获取上一篇
                    if(prevId != undefined){
                        this.$api.getArticleContent({id:prevId,type:this.$route.query.type,state:1}).then(res=>{
                            if(res.data.code == 200 && res.data.data.length>0){
                                this.prevArr = [{
                                    title: res.data.data[0].title,
                                    id: res.data.data[0].id,
                                }]
                            }
                            this.loadling1 = false;
                        });
                    }else{
                        this.loadling1 = false;
                        this.prevArr = [{
                            title: '没有更多了',
                            id: -1,
                        }]
                    }
                    //获取下一篇
                    if(nextId != undefined){
                        this.$api.getArticleContent({id:nextId,type:this.$route.query.type,state:1}).then(res=>{
                            if(res.data.code == 200 && res.data.data.length>0){
                                this.nextArr = [{
                                    title: res.data.data[0].title,
                                    id: res.data.data[0].id,
                                }]
                            }
                            this.loadling2 = false;
                        });
                    }else{
                        this.loadling2 = false;
                        this.nextArr = [{
                            title: '没有更多了',
                            id: -1,
                        }]
                    }
                    
                }
            })
        },
        //下一篇上一篇
        checkArticle(id){
            if(id === -1 || id == this.id){
                return;
            };
            this.id = Number(id);
            this.getArticleContent();
            this.loadling1 = true;
            this.loadling2 = true;
        },
        //保存浏览量到本地
        setShowCount(){
            let showCount = localStorage.getItem('showCount') || [];
            if(showCount.length <= 0){
                this.showCount = Math.floor(Math.random() * 5000) + 3000;
                showCount.push({
                    id:this.$route.query.id,
                    type:this.$route.query.type,
                    count:this.showCount,
                });
                localStorage.setItem('showCount',JSON.stringify(showCount));
            }else{
                let haveArr = JSON.parse(showCount).filter((item,index,arr)=>{
                    if(item.id == this.$route.query.id && item.type == this.$route.query.type){
                        item.count += 1;
                        this.showCount = item.count;
                        localStorage.setItem('showCount',JSON.stringify(arr));
                    }
                    return item.id == this.$route.query.id && item.type == this.$route.query.type
                })
                if(haveArr.length <= 0){
                    this.showCount = Math.floor(Math.random() * 5000) + 3000;
                    let arr = JSON.parse(showCount)
                    arr.push({
                         id:this.$route.query.id,
                         type:this.$route.query.type,
                         count:this.showCount,
                    })
                    localStorage.setItem('showCount',JSON.stringify(arr));
                }
            }
        },
    },
    created(){
        document.body.style.height = "100%"
        document.documentElement.style.height = "100%"
        let dateList = new Date().toDateString().split(" ");
        this.date.year = dateList[3];
        this.date.month = dateList[1];
        this.date.day = dateList[2];
        this.id = Number(this.$route.query.id);
        this.idList = JSON.parse(this.$route.query.idList);
    },
    mounted(){
        if(Number(this.id)!== -1){
            this.getArticleContent();
        };
        this.setShowCount()
    },
}
</script>

<style lang="scss" scoped>

.container{
    width: 1200px;
    margin: 0 auto;
    height: auto;
}
.pagetitle{
    color: #4D4D4D;
    font-size: 15px;
    font-weight: bold;
    margin: 20px 0;
    position: relative;
    .date{
        position: absolute;
        width: 100px;
        height: 95px;
        margin-top: 20px;
        padding: 10px;
        background: #E9EDF0;
        box-shadow: 7px 5px 16px 0px #C5C5C5, -8px -8px 27px 5px #FFFFFF, 0px 3px 8px 0px #F4F7FF, 2px 2px 8px 0px #DBDEE4;
        .day{
            font-size: 23px;
            color:#4D7BFF ;
        }
        p{
            color: #666666;
            margin-top: 8px;
            font-size: 14px;
        }
    }
}
.title{
    width: 83%;
    color: #4D4D4D;
    font-size: 21px;
    font-weight: bold;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    letter-spacing: 3px;
}
.small-title{
    width: 100%;
    color: #808080;
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin: 10px 0;
    p{margin: 0 15px;}
}
.text{
    text-indent: 2em;
    width: 832px;
    margin: 0 auto;
    margin-top: 20px;
    color: #4D4D4D;
    line-height: 35px;
    letter-spacing: 1px;
    white-space: pre-wrap;
    font-size: 16px;
    font-weight: 600;
}
.img-box{
    margin: 0 auto;
    width: 844px;
    height: 563px;
    img{width: 100%;height: 100%;}
}
.page{
    width: 850px;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4D4D4D;
    font-weight: bold;
    font-size: 15px;
    p{
        width: 50%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    p:hover{
        color: #4D7BFF;
        cursor: pointer;
    }
}

</style>